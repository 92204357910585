<template>
  <master-detail
    descriptionProperty="cod_desc"
    formTitle="Catálogo de Itens"
    :canDelete="false"
    :canEdit="!canView"
    :canView="canView"
    :cols="cols"
    :hasNewButton="false"
    :opts="opts"
    :resourceUrl="resourceUrl"
    :serverPagination="true"
    @onOpenFormDialog="setSelectedItem"
    :hasExportCSV="false"
  >
    <async-export-button type="catalogo" />
  </master-detail>
</template>

<script>
import { uniqBy } from 'lodash';

export default {
  components: {
    "async-export-button": () => import("@/components/async-export-button.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    client: function () {
      return this.getClient();
    },
    resourceUrl: function () {
      return `/v1/faturamento/catalogo/${this.client.clientId}`;
    },
    resourceProduto: function () {
      return this.apiResource(
        `/v1/faturamento/produtos/${this.client.clientId}`
      );
    },
    canView: function () {
      return this.$route.meta.viewMode === true;
    },
  },
  created: function () {
    this.resourceProduto
      .get()
      .then((response) => {
        const uniqProdutos = uniqBy(response, 'codigoProduto');
        this.opts.produtos = [
          ...uniqProdutos,
          { codigoProduto: -1, abreviacao: "CLASSIFICAÇÃO PENDENTE", incentivo: -1, disabled: true },
          { codigoProduto: 0, abreviacao: "NÃO INCENTIVADO", incentivo: -1 },
        ];
      });
  },
  data: function () {
    return {
      selectedItem: null,
      cols: [
        {
          key: "cod_item",
          name: "Código",
          type: this.$fieldTypes.TEXT,
          editable: false,
          filterable: true,
          colSize: 4,
        },
        {
          key: "descricao_item",
          name: "Descrição",
          type: this.$fieldTypes.TEXT,
          editable: false,
          filterable: true,
          colSize: 8,
        },
        {
          key: "codigoProduto",
          name: "Produto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "produtos", key: "codigoProduto", name: "abreviacao" },
          rules: [{ rule: "required" }],
          hideInTable: true,
          valueChanged: (codigo) => {
            this.selectedItem.pIncentivadoId = null;
            this.getModelos(codigo);
          },
        },
        {
          key: "pIncentivadoId",
          name: "Modelo",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "modelos", key: "id", name: "modelo" },
          rules: [{ rule: "requiredIf", params: {
            condition: () => this.selectedItem.pIncentivadoId === null,
          }}],
          hideInTable: true,
        },
        {
          key: "inf_adic_prod",
          name: "Inf. Adic. Produto",
          type: this.$fieldTypes.TEXT,
          editable: false,
          truncate: true,
          width: '100px',
          colSize: 6,
        },
        {
          key: "situacao_incentivo",
          name: "Situação Incentivo",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "sit_incentivo", key: "id", name: "name" },
          filterable: true,
          colSize: 6,
        },
        {
          key: "produto",
          name: "Produto",
          type: this.$fieldTypes.TEXT,
          filterable: true,
          hideInform: true,
        },
        {
          key: "modelo",
          name: "Modelo",
          type: this.$fieldTypes.TEXT,
          filterable: true,
          hideInform: true,
        },
        {
          key: "situacao_nf",
          name: "Origem",
          type: this.$fieldTypes.TEXT,
          editable: false,
          filterable: true,
          colSize: 6,
        },
        {
          key: "mes_cadastro",
          name: "Mês adição",
          type: this.$fieldTypes.MONTH,
          filterable: true,
          colSize: 6,
        },
         {
          key: "observacao",
          name: "Observação",
          truncate: true,
          width: '200px',
          type: this.$fieldTypes.TEXTAREA,
        },
      ],
      opts: {
        produtos: [],
        modelos: [],
        sit_incentivo: [
          { id: "NÃO", name: "NÃO", disabled: false },
          { id: "PPB", name: "PPB", disabled: false },
          { id: "TecNac", name: "TecNac", disabled: false },
          { id: "-1", name: "Indefinido", disabled: false },
        ],
      },
    };
  },
  methods: {
    setSelectedItem: function (selectedItem) {
      this.opts.modelos = [
        { id: -1, modelo: "CLASSIFICAÇÃO PENDENTE", disabled: true },
        { id: 0, modelo: "NÃO INCENTIVADO" },
      ];
      
      if (selectedItem.codigoProduto) {
        this.getModelos(selectedItem.codigoProduto);
      }

      this.selectedItem = selectedItem;
    },
    getModelos: function (codigoProduto) {
      if (codigoProduto > 0) {
        this
          .apiResource(`/v1/faturamento/produtosincentivados/${this.client.clientId}/${codigoProduto}`)
          .get()
          .then((response) => {
            this.opts.modelos = [
              ...response,
              { id: -1, modelo: "CLASSIFICAÇÃO PENDENTE", disabled: true },
              { id: 0, modelo: "NÃO INCENTIVADO" },
            ];
          });
      } else {
        this.opts.modelos = [
          { id: -1, modelo: "CLASSIFICAÇÃO PENDENTE", disabled: true },
          { id: 0, modelo: "NÃO INCENTIVADO" },
        ];
      }
    },
  },
};
</script>